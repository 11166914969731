<template>
  <div v-loading="loading" v-if="fileType == 'doc'">
    <VueOfficeDocx :src="fileAddress" :options="options" @rendered="renderedHandler" @error="errorHandler"
      v-if="fileType == 'doc'" />
  </div>
  <div v-loading="loading" v-else-if="fileType == 'excel'">
    <VueOfficeExcel :src="fileAddress" :options="options" @rendered="renderedHandler" @error="errorHandler"
      v-if="fileType == 'excel'" />
  </div>
  <div v-loading="loading" v-else-if="fileType == 'pdf'">
    <VueOfficePdf :src="fileAddress" :options="options" @rendered="renderedHandler" @error="errorHandler"
      v-if="fileType == 'pdf'" />
  </div>
  <div v-else-if="fileType == 'ppt'">
    <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + fileAddress" frameborder="0" width="100%"
      height="100%" v-if="fileType == 'ppt'"></iframe>
  </div>
</template>
<script>
import { Empty } from 'element-ui'
import VueOfficeDocx from '@vue-office/docx';
import VueOfficeExcel from '@vue-office/excel';
import VueOfficePdf from '@vue-office/pdf';
export default {
  name: 'TryOffice',
  components: {
    VueOfficePdf,
    VueOfficeExcel,
    VueOfficeDocx
  },
  props: {
    value: {// 绑定的值
      type: String,
      default: undefined
    },
    type: {// 文件类型
      type: String,
      default: "doc"
    },

  },
  data() {
    return {
      loading: false,
      fileAddress: this.value,
      fileType: this.type,
      options: {
        debug: false,
        showChanges: false,
      }
    }
  },
  watch: {
    value(n, o) {
      if (n && n != this.fileAddress) {
        this.fileAddress = n;
      }
    },
    type(n, o) {
      if (n) {
        this.fileType = n;
      }
    },

  },
  created() {
    this.loading = true;
  },
  methods: {
    renderedHandler() {
      this.$message({ type: "succ", message: "数据载入完成..." });
      this.loading = false;
    },
    errorHandler() {
      console.log(this.fileAddress);
      this.$message({ type: "error", message: "数据渲染失败..." });
      this.loading = false;
    },
  }
}
</script>
